export default {
  team_roles: [],
  user_roles: [],
  session: {
    logged: false,
    token: '',
    user_id: null,
    team_id: null,
    user_email: '',
    roles: [],
    permissions: [],
    status: ''
  },
  activeDashboard: {},
  apiKeys: {
    loading: false,
    allApiKeys: [],
    apiKeyOptions: []
  },
  datasetProviders: [],
  data: {
    coins: [],
    universal: {}
  },
  defaultFeeds: ['Firehose', 'Trending', 'Starred by The Tie', 'Starred by My Team', 'On-Chain Signal'],
  loadingStatus: {
    coins: false,
    static: false
  },
  notifications: {
    long: 10000,
    short: 5000
  },
  navItems: [
    {
      id: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard',
      priority: 8
    },
    {
      id: 'news',
      name: 'News',
      link: '/news',
      priority: 7
    },
    {
      id: 'alerts',
      name: 'Alerts',
      link: '/alerts',
      priority: 6
    },
    {
      id: 'screener',
      name: 'Coins Screener',
      link: '/screener',
      subpages: [],
      hideSubNav: true,
      priority: 6,
      subNavItems: [
        {
          id: 'coinScreener',
          name: 'Coins Screener',
          link: '/screener'
        },
        {
          id: 'sectorScreener',
          name: 'Sector Screener',
          link: '/sector-screener'
        },
        {
          id: 'ecosystemScreener',
          name: 'Ecosystem Screener',
          link: '/ecosystem-screener'
        }
      ]
    },
    {
      id: 'tradingView.research',
      name: 'Chart',
      link: '/charts',
      priority: 5,
      subNavItems: [
        {
          id: 'tradingView.research',
          name: 'Research Chart',
          link: '/charts'
        },
        {
          id: 'tradingView',
          name: 'Price Chart',
          link: '/charts/price'
        }
      ]
    },
    {
      id: 'tools',
      name: 'Tools',
      link: '/tools',
      priority: 5,
      subNavItems: [
        {
          id: 'apefluencer',
          name: 'Apefluencer',
          link: '/tools/apefluencer'
        },
        {
          id: 'derivatives',
          name: 'Derivatives',
          link: '/tools/derivatives'
        },
        {
          id: 'unlock',
          name: 'Token Unlocks',
          link: '/tools/unlock'
        },
        {
          id: 'governance',
          name: 'Governance',
          link: '/tools/governance'
        },
        {
          id: 'ecosystems',
          name: 'Ecosystems',
          link: '/tools/ecosystems'
        }
      ]
    },
    // {
    //   id: 'coins',
    //   name: 'Coins',
    //   link: '/coins',
    //   priority: 4
    // },
    {
      id: 'companies',
      name: 'Companies',
      link: '/companies',
      priority: 3,
      subNavItems: [
        {
          id: 'companies',
          name: 'Companies',
          link: '/companies'
        },
        {
          id: 'funds',
          name: 'Funds',
          link: '/funds'
        },
        {
          id: 'fundraising_trends',
          name: 'Fundraising Trends',
          link: '/fundraising_trends'
        }
      ]
    },
    {
      id: 'research',
      name: 'Research',
      link: '/research',
      priority: 2
    },
    {
      id: 'nfts',
      name: 'NFTs',
      link: '/nft',
      priority: 1
    }
  ],
  companyTagGroups: ['Companies'],
  sidebarData: {},
  onChainSidebarData: {},
  sidebars: {
    coin: false,
    correlated: false,
    onchain: false
  },
  supportedChains: [
    {
      id: 'ethereum',
      text: 'Ethereum',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/ethereum.png',
      address: 'https://etherscan.io',
      addressExtension: { transaction: 'tx', address: 'address', token: 'token' }
    },
    {
      id: 'avalanche',
      text: 'Avalanche (C-Chain)',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/avalanche.png',
      address: 'https://snowtrace.io',
      addressExtension: { transaction: 'tx', address: 'address', token: 'token' }
    },
    {
      id: 'polygon',
      text: 'Polygon',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/maticnetwork.png',
      address: 'https://polygonscan.com',
      addressExtension: { transaction: 'tx', address: 'address', token: 'token' }
    },
    {
      id: 'tezos',
      text: 'Tezos',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/tezos.png',
      address: 'https://tzkt.io',
      addressExtension: { transaction: '', address: '', token: '' }
    },
    {
      id: 'hedera',
      text: 'Hedera',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/hederahashgraph.png',
      address: 'https://hashscan.io/mainnet',
      addressExtension: { transaction: 'transaction', address: 'account', token: 'token' }
    },
    {
      id: 'bitcoin',
      text: 'Bitcoin',
      image: 'https://terminal.cloudfront.thetie.io/coin_images/bitcoin.png',
      address: 'https://btcscan.org',
      addressExtension: { transaction: 'transaction', address: 'account', token: 'token' }
    }
  ],
  path: 'dashboard',
  firstPath: '',
  lastPath: '',
  searchMode: false,
  categories: {
    coin: [],
    coin_sub_categories: [],
    company: []
  },
  sectorData: [],
  ecosystemData: [],
  sectorDatapointsConfig: [],
  ecosystemDatapointsConfig: [],
  tagColors: {
    Coins: 'text-green-400',
    Companies: 'text-yellow-400',
    'General Topics': 'text-blue-400',
    SigDevs: 'text-red-400',
    'Low Quality': 'text-gray-400',
    Social: 'text-blue-300',
    'On-Chain Signal': 'text-purple-400'
  },
  tagBackgroundColors: {
    Coins: 'bg-green-400',
    Companies: 'bg-yellow-400',
    'General Topics': 'bg-blue-400',
    SigDevs: 'bg-red-400',
    'Low Quality': 'bg-gray-400',
    Social: 'bg-blue-300'
  },
  sentimentClasses: {
    'Very Bearish': 'text-red-400',
    Bearish: 'text-red-400',
    Neutral: 'text-gray-400',
    Bullish: 'text-green-400',
    'Very Bullish': 'text-green-400'
  },
  companySlugs: [],
  coinDatapointsConfig: [],
  sigdevTagSubgroup: [],
  companiesTagSubgroup: {},
  sidebarCollapsed: localStorage.getItem('sidebarCollapsed') === 'true',
  sidebarCollapsedInt: localStorage.getItem('sidebarCollapsed') === 'true',
  hasSidebar: false,
  modal: {
    payload: {},
    status: false,
    name: ''
  },
  onchainAddressesModal: {
    name: '',
    status: false,
    payload: {}
  },
  onchainFolderModal: {
    status: false,
    payload: {}
  },
  watchlistAlertsModal: {
    status: false,
    payload: {}
  },
  metricTooltips: [],
  userNotifications: [],
  sentimentColumns: [
    'hourly_sentiment',
    'daily_sentiment_avg_30_days',
    'daily_sentiment_change_7_days',
    'daily_sentiment',
    'long_term_sentiment',
    'tweet_volume',
    'tweet_volume_1_minute',
    'tweet_volume_30_day_avg',
    'hype_to_activity_ratio',
    'tweet_volume_dominance',
    'tweet_unique_sources_percent_30_day_avg',
    'twitter_accounts',
    'nvtweet_ratio',
    'twitter_accounts_30_day_avg',
    'relative_tweet_volume',
    'tweet_unique_sources_percent',
    'tweet_unique_sources_percent_1_minute',
    'positive_tweets_percent',
    'negative_tweets_percent',
    'neutral_tweets_percent'
  ],
  customScreenersIgnoreList: [
    '1h_exchange_flow_supply_pct_ethereum',
    '24h_exchange_flow_supply_pct_ethereum',
    '1h_exchange_flow_supply_pct_ethereum',
    '24h_exchange_flow_supply_pct_ethereum',
    '1h_exchange_flow_supply_pct_polygon',
    '24h_exchange_flow_supply_pct_polygon',
    '1h_exchange_flow_supply_pct_avalanche',
    '24h_exchange_flow_supply_pct_avalanche',
    'all_time_high_price',
    'all_time_low_price',
    'beta',
    'circulating_supply',
    'down_from_all_time_high',
    'early_holder_balance_ethereum',
    'early_holder_balance_avalanche',
    'early_holder_balance_polygon',
    'early_holder_balance_1d_ethereum',
    'early_holder_balance_1d_avalanche',
    'early_holder_balance_1d_polygon',
    'early_holder_balance_7d_ethereum',
    'early_holder_balance_7d_avalanche',
    'early_holder_balance_7d_polygon',
    'early_holder_balance_30d_ethereum',
    'early_holder_balance_30d_avalanche',
    'early_holder_balance_30d_polygon',
    'early_holder_balance_1d_change_pct_ethereum',
    'early_holder_balance_1d_change_pct_avalanche',
    'early_holder_balance_1d_change_pct_polygon',
    'early_holder_balance_7d_change_pct_ethereum',
    'early_holder_balance_7d_change_pct_avalanche',
    'early_holder_balance_7d_change_pct_polygon',
    'early_holder_balance_30d_change_pct_ethereum',
    'early_holder_balance_30d_change_pct_avalanche',
    'early_holder_balance_30d_change_pct_polygon',
    'early_holder_supply_pct_ethereum',
    'early_holder_supply_pct_avalanche',
    'early_holder_supply_pct_polygon',
    'early_holder_supply_pct_1d_ethereum',
    'early_holder_supply_pct_1d_avalanche',
    'early_holder_supply_pct_1d_polygon',
    'early_holder_supply_pct_7d_ethereum',
    'early_holder_supply_pct_7d_avalanche',
    'early_holder_supply_pct_7d_polygon',
    'early_holder_supply_pct_30d_ethereum',
    'early_holder_supply_pct_30d_avalanche',
    'early_holder_supply_pct_30d_polygon',
    'expected_shortfall_95_pct',
    'expected_shortfall_99_pct',
    'max_supply',
    'pct_circulation_supply',
    'price',
    'live_price',
    'price_btc',
    'price_eth',
    'sharpe_ratio',
    'simple_moving_average_50_days',
    'simple_moving_average_change_50_days',
    'supply_in_circulation',
    'total_supply',
    'total_supply_held_pct_ethereum',
    'total_supply_held_pct_polygon',
    'total_supply_held_pct_avalanche',
    'up_from_all_time_low',
    'value_at_risk_95_pct',
    'value_at_risk_99_pct',
    'volatility_index',
    'whale_balance_ethereum',
    'whale_balance_avalanche',
    'whale_balance_polygon',
    'whale_balance_1d_ethereum',
    'whale_balance_1d_avalanche',
    'whale_balance_1d_polygon',
    'whale_balance_1d_change_pct_ethereum',
    'whale_balance_1d_change_pct_avalanche',
    'whale_balance_1d_change_pct_polygon',
    'whale_balance_30d_ethereum',
    'whale_balance_30d_avalanche',
    'whale_balance_30d_polygon',
    'whale_balance_30d_change_pct_ethereum',
    'whale_balance_30d_change_pct_avalanche',
    'whale_balance_30d_change_pct_polygon',
    'whale_balance_7d_ethereum',
    'whale_balance_7d_avalanche',
    'whale_balance_7d_polygon',
    'whale_balance_7d_change_pct_ethereum',
    'whale_balance_7d_change_pct_avalanche',
    'whale_balance_7d_change_pct_polygon',
    'whale_supply_pct_ethereum',
    'whale_supply_pct_avalanche',
    'whale_supply_pct_polygon',
    'whale_supply_pct_1d_ethereum',
    'whale_supply_pct_1d_avalanche',
    'whale_supply_pct_1d_polygon',
    'whale_supply_pct_7d_ethereum',
    'whale_supply_pct_7d_avalanche',
    'whale_supply_pct_7d_polygon',
    'whale_supply_pct_30d_ethereum',
    'whale_supply_pct_30d_avalanche',
    'whale_supply_pct_30d_polygon',
    'retail_balance',
    'retail_supply_pct',
    'retail_balance_1d',
    'retail_supply_pct_1d',
    'retail_balance_7d',
    'retail_supply_pct_7d',
    'retail_balance_30d',
    'retail_supply_pct_30d',
    'retail_balance_1d_change_pct',
    'retail_balance_7d_change_pct',
    'retail_balance_30d_change_pct',
    'num_inc_24hr_ethereum',
    'num_dec_24hr_ethereum',
    'num_inc_24hr_rel_change_pct',
    'num_dec_24hr_rel_change_pct',
    'num_inc_24hr_avalanche',
    'num_dec_24hr_avalanche',
    'num_inc_24hr_polygon',
    'num_dec_24hr_polygon',
    'num_inc_30d_avg_ethereum',
    'num_dec_30d_avg_ethereum',
    'num_inc_30d_avg_avalanche',
    'num_dec_30d_avg_avalanche',
    'num_inc_30d_avg_polygon',
    'num_dec_30d_avg_polygon',
    '1h_exchange_flow_supply_pct_tezos',
    '24h_exchange_flow_supply_pct_tezos',
    'early_holder_balance_tezos',
    'early_holder_balance_1d_tezos',
    'early_holder_balance_7d_tezos',
    'early_holder_balance_30d_tezos',
    'early_holder_balance_1d_change_pct_tezos',
    'early_holder_balance_7d_change_pct_tezos',
    'early_holder_balance_30d_change_pct_tezos',
    'early_holder_supply_pct_tezos',
    'early_holder_supply_pct_1d_tezos',
    'early_holder_supply_pct_7d_tezos',
    'early_holder_supply_pct_30d_tezos',
    'total_supply_held_pct_tezos',
    'whale_balance_tezos',
    'whale_balance_1d_tezos',
    'whale_balance_1d_change_pct_tezos',
    'whale_balance_30d_tezos',
    'whale_balance_30d_change_pct_tezos',
    'whale_balance_7d_tezos',
    'whale_balance_7d_change_pct_tezos',
    'whale_supply_pct_tezos',
    'whale_supply_pct_1d_tezos',
    'whale_supply_pct_7d_tezos',
    'whale_supply_pct_30d_tezos',
    'num_inc_24hr_tezos',
    'num_dec_24hr_tezos',
    'num_inc_30d_avg_tezos',
    'num_dec_30d_avg_tezos',
    '1h_exchange_flow_supply_pct_hedera',
    '24h_exchange_flow_supply_pct_hedera',
    'early_holder_balance_hedera',
    'early_holder_balance_1d_hedera',
    'early_holder_balance_7d_hedera',
    'early_holder_balance_30d_hedera',
    'early_holder_balance_1d_change_pct_hedera',
    'early_holder_balance_7d_change_pct_hedera',
    'early_holder_balance_30d_change_pct_hedera',
    'early_holder_supply_pct_hedera',
    'early_holder_supply_pct_1d_hedera',
    'early_holder_supply_pct_7d_hedera',
    'early_holder_supply_pct_30d_hedera',
    'total_supply_held_pct_hedera',
    'whale_balance_hedera',
    'whale_balance_1d_hedera',
    'whale_balance_1d_change_pct_hedera',
    'whale_balance_30d_hedera',
    'whale_balance_30d_change_pct_hedera',
    'whale_balance_7d_hedera',
    'whale_balance_7d_change_pct_hedera',
    'whale_supply_pct_hedera',
    'whale_supply_pct_1d_hedera',
    'whale_supply_pct_7d_hedera',
    'whale_supply_pct_30d_hedera',
    'num_inc_24hr_hedera',
    'num_dec_24hr_hedera',
    'num_inc_30d_avg_hedera',
    'num_dec_30d_avg_hedera',
    '24h_inflow_ethereum',
    '24h_outflow_ethereum',
    '1h_inflow_ethereum',
    '1h_outflow_ethereum',
    '1h_exchange_netflows_ethereum',
    '24h_exchange_netflows_ethereum',
    '24h_inflow_avalanche',
    '24h_outflow_avalanche',
    '1h_inflow_avalanche',
    '1h_outflow_avalanche',
    '1h_exchange_netflows_avalanche',
    '24h_exchange_netflows_avalanche',
    '24h_inflow_polygon',
    '24h_outflow_polygon',
    '1h_inflow_polygon',
    '1h_outflow_polygon',
    '1h_exchange_netflows_polygon',
    '24h_exchange_netflows_polygon',
    '24h_inflow_tezos',
    '24h_outflow_tezos',
    '1h_inflow_tezos',
    '1h_outflow_tezos',
    '1h_exchange_netflows_tezos',
    '24h_exchange_netflows_tezos',
    '24h_inflow_hedera',
    '24h_outflow_hedera',
    '1h_inflow_hedera',
    '1h_outflow_hedera',
    '1h_exchange_netflows_hedera',
    '24h_exchange_netflows_hedera',
    'fifty_two_week_high',
    'ytd_high',
    'down_from_52_week_high',
    'down_from_ytd_high',
    '1h_exchange_flow_supply_pct_bitcoin',
    '24h_exchange_flow_supply_pct_bitcoin',
    '1h_exchange_flow_supply_pct_bitcoin',
    '24h_exchange_flow_supply_pct_bitcoin',
    'early_holder_balance_bitcoin',
    'early_holder_balance_1d_bitcoin',
    'early_holder_balance_7d_bitcoin',
    'early_holder_balance_30d_bitcoin',
    'early_holder_balance_1d_change_pct_bitcoin',
    'early_holder_balance_7d_change_pct_bitcoin',
    'early_holder_balance_30d_change_pct_bitcoin',
    'early_holder_supply_pct_bitcoin',
    'early_holder_supply_pct_1d_bitcoin',
    'early_holder_supply_pct_7d_bitcoin',
    'early_holder_supply_pct_30d_bitcoin',
    'total_supply_held_pct_bitcoin',
    'total_supply_held_pct_bitcoin',
    'whale_balance_bitcoin',
    'whale_balance_1d_bitcoin',
    'whale_balance_1d_change_pct_bitcoin',
    'whale_balance_30d_bitcoin',
    'whale_balance_30d_change_pct_bitcoin',
    'whale_balance_7d_bitcoin',
    'whale_balance_7d_change_pct_bitcoin',
    'whale_supply_pct_bitcoin',
    'whale_supply_pct_1d_bitcoin',
    'whale_supply_pct_7d_bitcoin',
    'whale_supply_pct_30d_bitcoin',
    'num_inc_24hr_bitcoin',
    'num_dec_24hr_bitcoin',
    'num_inc_30d_avg_bitcoin',
    'num_dec_30d_avg_bitcoin',
    'num_inc_30d_avg_bitcoin',
    'num_dec_30d_avg_bitcoin',
    '24h_inflow_bitcoin',
    '24h_outflow_bitcoin',
    '1h_inflow_bitcoin',
    '1h_outflow_bitcoin',
    '1h_exchange_netflows_bitcoin',
    '24h_exchange_netflows_bitcoin',
    '1h_exchange_flow_supply_pct_agg',
    '24h_exchange_flow_supply_pct_agg',
    '1h_exchange_flow_supply_pct_agg',
    '24h_exchange_flow_supply_pct_agg',
    'total_supply_held_pct_agg',
    'whale_balance_agg',
    'whale_balance_1d_agg',
    'whale_balance_1d_change_pct_agg',
    'whale_balance_30d_agg',
    'whale_balance_30d_change_pct_agg',
    'whale_balance_7d_agg',
    'whale_balance_7d_change_pct_agg',
    'whale_supply_pct_agg',
    'whale_supply_pct_1d_agg',
    'whale_supply_pct_7d_agg',
    'whale_supply_pct_30d_agg',
    'num_inc_24hr_agg',
    'num_dec_24hr_agg',
    'num_inc_30d_avg_agg',
    'num_dec_30d_avg_agg',
    '24h_inflow_agg',
    '24h_outflow_agg',
    '1h_inflow_agg',
    '1h_outflow_agg',
    '1h_exchange_netflows_agg',
    '24h_exchange_netflows_agg',
    'active_validators',
    'annualized_rewards',
    'annualized_rewards_usd',
    'average_validator_balance',
    'benchmark_commission',
    'benchmark_daily_fees',
    'block_height',
    'block_time',
    'block_time_30d',
    'consensus_apr',
    'current_slot',
    'eligible_supply',
    'execution_apr',
    'fee_revenue',
    'inactive_validators',
    'inflation_rate',
    'lido_operator_count',
    'net_staking_flow',
    'net_staking_flow_24h',
    'net_staking_flow_30d',
    'net_staking_flow_365d',
    'net_staking_flow_7d',
    'real_reward_rate',
    'reward_rate',
    'r_r_ratio',
    'expected_solo_staking_reward_rate',
    'staked_tokens',
    'staked_tokens_trend_24h',
    'stakewise_operator_count',
    'staking_marketcap',
    'staking_ratio',
    'staking_roi_365d',
    'total_roi_365d',
    'total_staking_wallets',
    'total_validators',
    'withdrawal_queue',
    'funding',
    'open_interest',
    'next_fr',
    'yield',
    'oi_change_1h',
    'oi_change_24h',
    'liquidations_long',
    'liquidations_short',
    'unlocks_1d',
    'unlocks_market_cap_percent_1d',
    'unlocks_trading_vol_percent_1d',
    'unlocks_7d',
    'unlocks_market_cap_percent_7d',
    'unlocks_trading_vol_percent_7d',
    'unlocks_30d',
    'unlocks_market_cap_percent_30d',
    'unlocks_trading_vol_percent_30d',
    'unlocks_90d',
    'unlocks_market_cap_percent_90d',
    'unlocks_trading_vol_percent_90d',
    'unlocks_365d',
    'unlocks_market_cap_percent_365d',
    'unlocks_trading_vol_percent_365d'
  ],
  defaultScreenerColumns: [
    'price',
    'market_cap',
    'diluted_valuation',
    'price_return_1_hour',
    'price_return_24_hours',
    'trading_volume',
    'relative_trade_volume',
    'tweet_volume',
    'relative_tweet_volume'
  ],
  widgetOutputChannels: {
    channel_a: null,
    channel_b: null,
    channel_c: null,
    channel_d: null,
    channel_e: null,
    channel_f: null,
    channel_g: null,
    channel_h: null
  },
  gasFees: {},
  gasFeesLoader: true,
  streamUserToken: null,
  streamClient: null,
  streamUser: null,
  giphyApiKey: null,
  unreadChannelsCount: null,
  previewImage: null,
  streamCompanies: null,
  channels: [],
  rejectedChannels: [],
  mutedChannels: [],
  requestChannels: [],
  messagingAccess: null,
  streamUsers: [],
  folders: [],
  termsOfServiceStatus: null,
  onchainAddresses: [],
  confirmationDialogOptions: {
    show: false,
    resolve: null,
    title: 'Action Required',
    message: 'Are you sure?',
    confirmText: 'Proceed',
    rejectText: 'Cancel'
  },
  apiRoles: ['sentiment_api', 'sigdev_api', 'onchain_api', 'api_user'],
  coinChain: 'ethereum',
  chartColors: [
    '#3B82F6',
    '#EF4444',
    '#10B981',
    '#F59E0B',
    '#D946EF',
    '#60A5FA',
    '#F87171',
    '#34D399',
    '#FBBF24',
    '#E879F9',
    '#2563EB',
    '#DC2626',
    '#059669',
    '#D97706',
    '#C026D3',
    '#1D4ED8',
    '#B91C1C',
    '#047857',
    '#845309',
    '#A21CAF',
    '#1E40AF',
    '#991B1B',
    '#065F46',
    '#92400E',
    '#86198F'
  ],
  channelsLoaded: false,
  onlyCompliance: false,
  unlockCoins: [],
  chartMetricOptions: [
    { id: 'price', name: 'Price' },
    { id: 'daily_sentiment', name: 'Daily Sentiment' },
    { id: 'tweet_volume', name: 'Tweet Volume' },
    { id: 'trading_volume', name: 'Trading Volume' },
    { id: 'price_return_24_hours', name: 'Price Change 24 Hours' },
    { id: 'price_return_1_hour', name: 'Price Change 1 Hour' },
    { id: 'daily_sentiment_avg_30_days', name: 'Daily Sentiment Avg 30 Days' },
    { id: 'daily_sentiment_change_7_days', name: 'Daily Sentiment Change 7 Days' },
    { id: 'daily_sentiment_momentum', name: 'Daily Sentiment Momentum' },
    { id: 'hourly_sentiment', name: 'Hourly Sentiment' },
    { id: 'long_term_sentiment', name: 'Long Term Sentiment' },
    { id: 'market_cap', name: 'Market Cap' },
    { id: 'price_btc', name: 'Price BTC' },
    { id: 'price_return_30_days', name: 'Price Change 30 Days' },
    { id: 'price_return_btc_1_hour', name: 'Price Change BTC 1 hour' },
    { id: 'trading_volume_30_day_avg', name: 'Trading Volume 30 Days Avg' },
    { id: 'tweet_volume_30_day_avg', name: 'Tweet Volume 30 Days Avg' },
    { id: 'tweet_volume_dominance', name: 'Tweet Volume Dominance' },
    { id: 'trading_volume_dominance', name: 'Trading Volume Dominance' },
    { id: 'price_return_btc_24_hours', name: 'Price Change BTC 24 Hours' },
    { id: 'price_return_btc_30_days', name: 'Price Change BTC (30D)' },
    { id: 'relative_trade_volume', name: 'Relative Trading Volume' },
    { id: 'simple_moving_average_50_days', name: '50D SMA' },
    { id: 'simple_moving_average_change_50_days', name: '50D SMA Change' },
    { id: 'hype_to_activity_ratio', name: 'Hype To Activity Ratio' },
    { id: 'nvtweet_ratio', name: 'NVTweet Ratio' },
    { id: 'relative_tweet_volume', name: 'Relative Tweet Volume' },
    { id: 'tweet_unique_sources_percent', name: 'Tweet Unique Sources %' },
    { id: 'tweet_unique_sources_percent_30_day_avg', name: '30D Avg Unique Tweet Sources' },
    { id: 'twitter_accounts', name: 'Twitter Accounts' },
    { id: 'twitter_accounts_30_day_avg', name: 'Twitter Accounts 30D AVG' },
    { id: 'tweet_volume_1_minute', name: 'Tweet Volume 1 Minute' },
    { id: 'tweet_unique_sources_percent_1_minute', name: 'Tweet Unique Sources % 1 Min' },
    { id: 'positive_tweets_percent', name: 'Positive Tweet %' },
    { id: 'negative_tweets_percent', name: 'Negative Tweet %' },
    { id: 'news_stories_v2_1_day', name: 'News Stories 1 Day' },
    { id: 'news_stories_v2_30_day_avg', name: 'News Stories 30 Day' },
    { id: 'news_stories_v2_7_day', name: 'News Stories 7 Day' },
    { id: 'relative_news_v2', name: 'Relative News' },
    { id: 'market_cap_dominance', name: 'Market Cap Dominance' },
    { id: 'tvl', name: 'Total Value Locked', onlyCoin: true },
    { id: 'mcap_/_tvl', name: 'MCAP/TVL', onlyCoin: true },
    { id: 'youtube_view_count', name: 'YouTube Views' },
    { id: 'youtube_view_count_30d_avg', name: 'YouTube Views (30d Avg)' },
    //Ethereum
    { id: 'num_inc_24_ethereum', name: '(ETH) Addresses with Increased Balance' },
    { id: 'num_dec_24_ethereum', name: '(ETH) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_ethereum', name: '(ETH) Transactions' },
    { id: 'num_active_addrs_24h_ethereum', name: '(ETH) Active Addresses' },
    { id: 'num_inc_30d_avg_ethereum', name: '(ETH) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_ethereum', name: '(ETH) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_ethereum', name: '(ETH) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_ethereum', name: '(ETH) Active Addresses (30d Avg)' },
    { id: 'supply_pct_ethereum', name: '(ETH) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_ethereum', name: '(ETH) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_ethereum', name: '(ETH) TVL', onlyCoin: true },

    //Polygon
    { id: 'num_inc_24_polygon', name: '(Matic) Addresses with Increased Balance' },
    { id: 'num_dec_24_polygon', name: '(Matic) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_polygon', name: '(Matic) Transactions' },
    { id: 'num_active_addrs_24h_polygon', name: '(Matic) Active Addresses' },
    { id: 'num_inc_30d_avg_polygon', name: '(Matic) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_polygon', name: '(Matic) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_polygon', name: '(Matic) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_polygon', name: '(Matic) Active Addresses (30d Avg)' },
    { id: 'supply_pct_polygon', name: '(Matic) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_polygon', name: '(Matic) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_polygon', name: '(Matic) TVL', onlyCoin: true },

    // Avalanche
    { id: 'num_inc_24_avalanche', name: '(AVAX) Addresses with Increased Balance' },
    { id: 'num_dec_24_avalanche', name: '(AVAX) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_avalanche', name: '(AVAX) Transactions' },
    { id: 'num_active_addrs_24h_avalanche', name: '(AVAX) Active Addresses' },
    { id: 'num_inc_30d_avg_avalanche', name: '(AVAX) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_avalanche', name: '(AVAX) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_avalanche', name: '(AVAX) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_avalanche', name: '(AVAX) Active Addresses (30d Avg)' },
    { id: 'supply_pct_avalanche', name: '(AVAX) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_avalanche', name: '(AVAX) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_avalanche', name: '(AVAX) TVL', onlyCoin: true },

    // Tezos
    { id: 'num_inc_24_tezos', name: '(XTZ) Addresses with Increased Balance' },
    { id: 'num_dec_24_tezos', name: '(XTZ) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_tezos', name: '(XTZ) Transactions' },
    { id: 'num_active_addrs_24h_tezos', name: '(XTZ) Active Addresses' },
    { id: 'num_inc_30d_avg_tezos', name: '(XTZ) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_tezos', name: '(XTZ) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_tezos', name: '(XTZ) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_tezos', name: '(XTZ) Active Addresses (30d Avg)' },
    { id: 'supply_pct_tezos', name: '(XTZ) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_tezos', name: '(XTZ) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_tezos', name: '(XTZ) TVL', onlyCoin: true },

    // Hedera
    { id: 'num_inc_24_hedera', name: '(HBAR) Addresses with Increased Balance' },
    { id: 'num_dec_24_hedera', name: '(HBAR) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_hedera', name: '(HBAR) Transactions' },
    { id: 'num_active_addrs_24h_hedera', name: '(HBAR) Active Addresses' },
    { id: 'num_inc_30d_avg_hedera', name: '(HBAR) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_hedera', name: '(HBAR) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_hedera', name: '(HBAR) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_hedera', name: '(HBAR) Active Addresses (30d Avg)' },
    { id: 'supply_pct_hedera', name: '(HBAR) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_hedera', name: '(HBAR) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_hedera', name: '(HBAR) TVL', onlyCoin: true },

    //Bitcoin
    { id: 'num_inc_24_bitcoin', name: '(BTC) Addresses with Increased Balance' },
    { id: 'num_dec_24_bitcoin', name: '(BTC) Addresses with Decreased Balance' },
    { id: 'num_txs_24h_bitcoin', name: '(BTC) Transactions' },
    { id: 'num_active_addrs_24h_bitcoin', name: '(BTC) Active Addresses' },
    { id: 'num_inc_30d_avg_bitcoin', name: '(BTC) Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_bitcoin', name: '(BTC) Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_bitcoin', name: '(BTC) Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_bitcoin', name: '(BTC) Active Addresses (30d Avg)' },
    { id: 'supply_pct_bitcoin', name: '(BTC) % of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_bitcoin', name: '(BTC) Exchange Net Flow', onlyCoin: true },
    { id: 'tvl_bitcoin', name: '(BTC) TVL', onlyCoin: true },

    //Aggregated Chain Columns
    { id: 'num_inc_24_agg', name: 'Addresses with Increased Balance' },
    { id: 'num_dec_24_agg', name: 'Addresses with Decreased Balance' },
    { id: 'num_txs_24h_agg', name: 'Transactions' },
    { id: 'num_active_addrs_24h_agg', name: 'Active Addresses' },
    { id: 'num_inc_30d_avg_agg', name: 'Addresses with Increased Balance (30d Avg)' },
    { id: 'num_dec_30d_avg_agg', name: 'Addresses with Decreased Balance (30d Avg)' },
    { id: 'num_tx_30d_avg_agg', name: 'Transactions (30d Avg)' },
    { id: 'num_active_addrs_30d_avg_agg', name: 'Active Addresses (30d Avg)' },
    { id: 'supply_pct_agg', name: '% of Supply on Exchange', onlyCoin: true },
    { id: 'flow_pct_agg', name: 'Exchange Net Flow', onlyCoin: true },

    { id: 'supply_in_circulation', name: '% of Supply in Circulation' },
    { id: 'commit_count', name: 'Monthly Commits' },
    { id: 'active_users_30d', name: 'Monthly Active Developers' },
    { id: 'diluted_valuation', name: 'Fully Diluted Valuation' },
    { id: 'beta', name: 'Beta' },
    { id: 'sharpe_ratio', name: 'Sharpe Ratio' },
    { id: 'volatility_index', name: 'Realized Volatility (Annualized)' },
    { id: 'circulating_supply', name: 'Circulating Supply' },
    { id: 'max_supply', name: 'Max Supply' },
    { id: 'total_supply', name: 'Total Supply' },

    // STAKING
    { id: 'active_validators', name: 'Active Validators', onlyCoin: true },
    { id: 'annualized_rewards', name: 'Annualized Rewards', onlyCoin: true },
    { id: 'annualized_rewards_usd', name: 'Annualized Rewards USD', onlyCoin: true },
    { id: 'average_validator_balance', name: 'Average Validator Balance', onlyCoin: true },
    { id: 'benchmark_commission', name: 'Benchmark Commission', onlyCoin: true },
    { id: 'benchmark_daily_fees', name: 'Benchmark Daily Fees', onlyCoin: true },
    { id: 'block_height', name: 'Block Height', onlyCoin: true },
    { id: 'block_time', name: 'Block Time', onlyCoin: true },
    { id: 'block_time_30d', name: 'Block Time 30d', onlyCoin: true },
    { id: 'consensus_apr', name: 'Consensus APR', onlyCoin: true },
    { id: 'current_slot', name: 'Current Slot', onlyCoin: true },
    { id: 'eligible_supply', name: 'Eligible Supply', onlyCoin: true },
    { id: 'execution_apr', name: 'Execution APR', onlyCoin: true },
    { id: 'fee_revenue', name: 'Fee Revenue', onlyCoin: true },
    { id: 'inactive_validators', name: 'Inactive Validators', onlyCoin: true },
    { id: 'inflation_rate', name: 'Inflation Rate', onlyCoin: true },
    { id: 'lido_operator_count', name: 'Lido Operator Count', onlyCoin: true },
    { id: 'net_staking_flow', name: 'Net Staking Flow', onlyCoin: true },
    { id: 'net_staking_flow_24h', name: 'Net Staking Flow 24h', onlyCoin: true },
    { id: 'net_staking_flow_30d', name: 'Net Staking Flow 30d', onlyCoin: true },
    { id: 'net_staking_flow_365d', name: 'Net Staking Flow 365d', onlyCoin: true },
    { id: 'net_staking_flow_7d', name: 'Net Staking Flow 7d', onlyCoin: true },
    { id: 'real_reward_rate', name: 'Real Reward Rate', onlyCoin: true },
    { id: 'reward_rate', name: 'Reward Rate', onlyCoin: true },
    { id: 'r_r_ratio', name: 'R R Ratio', onlyCoin: true },
    { id: 'expected_solo_staking_reward_rate', name: 'Solo Staking Reward Rate', onlyCoin: true },
    { id: 'staked_tokens', name: 'Staked Tokens', onlyCoin: true },
    { id: 'staked_tokens_trend_24h', name: 'Staked Tokens Trend 24h', onlyCoin: true },
    { id: 'stakewise_operator_count', name: 'Stakewise Operator Count', onlyCoin: true },
    { id: 'staking_marketcap', name: 'Staking Marketcap', onlyCoin: true },
    { id: 'staking_ratio', name: 'Staking Ratio', onlyCoin: true },
    { id: 'staking_roi_365d', name: 'Staking ROI 365d', onlyCoin: true },
    { id: 'total_roi_365d', name: 'Total ROI 365d', onlyCoin: true },
    { id: 'total_staking_wallets', name: 'Total Staking Wallets', onlyCoin: true },
    { id: 'total_validators', name: 'Total Validators', onlyCoin: true },
    { id: 'withdrawal_queue', name: 'Withdrawal Queue Minutes', onlyCoin: true }
  ]
};
